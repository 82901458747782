/* SurveyResults.module.scss */

/* --------------------------------------
   Basic modal & container styling 
--------------------------------------- */
.modalBody {
  display: flex;
  flex-direction: column;
  color: black !important;
}

.resultsModal {
  max-width: 80%;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px; /* more rounded */
  
  :global(.modal-dialog) {
    max-width: 80%;
    width: 100%;
  }
  
  :global(.modal-content) {
    /* If needed for more height:
       max-height: 90vh;
       overflow-y: auto; */
    border-radius: 12px; /* keep it looking round */
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #f8f8ff; /* subtle background color */
}

.modalTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  color: black;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6c757d;
  
  &:hover {
    color: #343a40;
  }
}

.modalSubtitle {
  font-size: 0.9rem;
  color: #6c757d;
  margin-top: 0.5rem;
}

/* --------------------------------------
 Loading & Alert styling 
--------------------------------------- */
.loadingContainer {
  text-align: center;
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
}

.alertMessage {
  font-size: 1.2em;
  font-weight: 600;
}

/* --------------------------------------
 Filter / Search UI 
--------------------------------------- */
.filterContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.filterInput {
  flex-grow: 1;
  margin-right: 0.5rem;
  color: black !important;
  font-weight: 600;
}

.searchIcon {
  font-size: 1.25rem;
  color: #6c757d;
}

.filterModalOption {
  color: black !important;
  font-size: 1.5em;
  font-weight: 600;
}

.cumulativeCheckLabel {
  color: black !important;
  font-size: 1.5em;
}

.filterModalCheckbox {
  color: black !important;
  font-size: 1.5em;
  visibility: initial !important;
  opacity: 1 !important;
}

.filterModal .modal-content {
  background-color: white !important;
  color: black !important;
  font-size: 1.5em;
  border-radius: 12px;
}

.filterModal .modal-header,
.filterModal .modal-footer {
  border: none;
}

.filterModal .form-group label {
  font-size: 1.25em;
}

.filterModal .form-check-label {
  font-size: 1.25em;
}

.filterModal .form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.filterModal .modal-footer .btn {
  font-size: 1.25em;
}

.filterSection {
  margin-bottom: 1rem;
}

.filterOptions {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.sbtOptions {
  margin-top: 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 0.25rem;
}

/* Boundary box for top filter & export actions */
.topActionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding: 15px;
  border: 2px solid #dee2e6;
  border-radius: 12px;
  background-color: #f8f8ff;
}

.sbtFilterContainer {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-right: 20px;
}

.filterBoxLabel {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #333;
}

/* --------------------------------------
 Table or list of responses 
--------------------------------------- */
.responseList {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.responseItem {
  margin-bottom: 1rem;
}

.singleResponseCard {
  margin-bottom: 10px !important;
  background-color: blue;
  border-radius: 8px !important;
  padding: 5px !important;
}

.responseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 15px 15px 15px 15px !important;
  font-size: 1.1rem;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
}

.responderAddress {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.responderLink {
  color: white;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

.externalLink {
  display: inline-flex;
  margin-left: 0.5rem;
  color: white;
  font-size: 1.1rem; /* bigger icon */
  
  &:hover {
    color: #f8f9fa;
  }
}

.lockIcon {
  margin-left: 0.5rem;
  font-size: 1.2rem; /* bigger lock icon */
}

.caretIcon {
  font-size: 1.5rem; /* bigger caret */
  transition: transform 0.2s ease;
}

.responseCard {
  background-color: #f8f9fa;
  border-radius: 0 0 8px 8px;
  padding: 1rem;
}

/* For toggling question expansions in aggregator mode */
.questionSummaryCard {
  background-color: grey !important;
  margin-bottom: 5px !important;
}

.questionSummaryHeader {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background-color: #2c10ff96 !important;
  padding: 15px 15px 15px 15px !important;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
}

#surveyResultsCollapse {
  box-shadow: none !important;
}

.darkCardBody {
  padding: 1rem;
  background-color: grey !important;
}

/* Aggregator improvements */
.aggregatorSummaryCard {
  background-color: #dce3f7 !important;
  margin-bottom: 10px !important;
  border-radius: 12px; /* rounder corners */
  border: 1px solid #c0caee;
}

.aggregatorDarkCardBody {
  padding: 0px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0px !important;
  box-shadow: 0px !important;
}

.questionSummaries {
  margin-top: 1rem;
}

.bookmarkIcon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 1rem; /* bigger bookmark icon */
}

/* Additional styling for question titles displayed */
.questionTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: white !important; /* Used in some headers */
}

.clickableQuestionId {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
  
  &:hover {
    text-decoration: underline;
  }
}

/* 
Updated questionIdTable styling with 
grey background, white text, bigger icons, etc.
*/
.questionIdTableWrapper {
  margin-top: 10px;
  background-color: #3a3a3a; 
  border-radius: 12px;
  padding: 10px; 
}

.questionIdTable {
  width: 100%;
  border-radius: 12px;
  font-size: 0.9rem;
  background-color: transparent; /* using parent background */
  
  thead tr th {
    background-color: #505050; /* darker grey for header */
    text-align: center;
    color: #fff;
    border-bottom: 2px solid #bdbdbd;
    font-weight: bold;
    padding: 10px;
  }

  tbody tr td {
    background-color: #2b2b2b;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 10px;
    border-color: #4b4b4b;
  }
}

/* Make sure the prompt column is left-aligned. */
.promptColumn {
  text-align: left !important;
}

/* Actions column button styling */
.tableActionButton {
  background-color: #5a5a5a;
  border: none;
  color: #fff;
  margin-right: 5px;
  font-size: 0.9rem;
  border-radius: 6px;

  &:hover {
    background-color: #777;
    color: #fff;
  }
}

/* Larger icons inside tables or cards */
.biggerIcon {
  font-size: 1.6rem; 
  margin-left: 5px;
  cursor: pointer;
}

#questionSummaryHeaderIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* NEW: Smaller, half-opacity refresh icon for "Refresh" actions */
.refreshIcon {
  font-size: 1rem;
  opacity: 0.5;
  margin-left: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.refreshIcon:hover {
  opacity: 0.7;
}

/* --------------------------------------
 Export / Download / CSV section 
--------------------------------------- */
.exportContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0; 
}

.exportLabel {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 1rem;
  color: #333;
}

.exportOptions {
  display: flex;
  align-items: center;
  margin-top: 10px; /* stacked look in smaller screens */
}

.exportDropdown {
  background-color: white;
  border: 1px solid #ced4da;
  color: white;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 8px; /* rounder dropdown */
  padding: 6px 12px;

  &:hover,
  &:focus {
    background-color: #f8f9fa;
    color: #212529;
  }

  .dropdown-menu {
    position: absolute;
    will-change: transform;
    border-radius: 8px;
  }
}

.downloadButton {
  background-color: #28a745;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 8px; /* rounder corners */
  padding: 6px 12px;
  &:hover,
  &:focus {
    background-color: #218838;
  }
}

/* Additional styling for custom dropdown items (used in some exports) */
.dropdownItem {
  color: black !important;
  text-transform: uppercase;
  font-size: 1.5em !important;
  font-weight: 600 !important;
  
  &.questionsItem {
    background-color: #ffe6e6 !important; /* pastel pink */
    font-style: italic;
    
    &:hover,
    &.active {
      background-color: #ffcccc !important; /* darker pastel pink */
    }
  }
  
  &.surveyItem {
    background-color: #ffffd1 !important; /* pastel yellow */
    
    &:hover,
    &.active {
      background-color: #ffff99 !important; /* darker pastel yellow */
    }
  }
}

/* --------------------------------------
 Show / Hide Results Buttons 
--------------------------------------- */
#filterButton,
#showResultsButton {
  background: transparent;
  font-weight: 600;
  padding: 10px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.9;
  margin-right: 10px;
}

#showResultsButton {
  opacity: 0.5;
}

/* If you have top-level container for results (optional) */
.topButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* --------------------------------------
 Sizing & layout for the encryption / uploading text 
--------------------------------------- */
#uploadingEncryptingText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

/* --------------------------------------
 Reused button styles 
--------------------------------------- */
.createSurveyButton {
  width: 100%;
  padding: 15px;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  color: white;
  transition: background-color 0.3s ease;
  
  &:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    color: white;
  }
}

.errorButton {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.errorMessage {
  margin-top: 10px;
  color: #dc3545;
  font-weight: bold;
}

/* --------------------------------------
 Survey submission progress bars 
--------------------------------------- */
.progressBarContainer {
  margin-top: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

.progressBar {
  height: 10px;
  background-color: #28a745;
  transition: width 0.5s ease;
}

/* --------------------------------------
 Survey submission confirmation 
--------------------------------------- */
.surveySubmissionConfirmation {
  margin-top: 30px;
  padding: 20px;
  background-color: #d4edda;
  border-radius: 8px;
  border: 1px solid #c3e6cb;

  h3 {
    color: #155724;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.1em;
    color: #1e7e34;
  }
}

.surveyActions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.copySurveyLinkButton,
.viewSurveyLink {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.copySurveyLinkButton {
  background-color: #28a745;
  color: white;
  border: none;
  margin-right: 10px;
  
  &:hover {
    background-color: #218838;
  }
}

.viewSurveyLink {
  background-color: #17a2b8;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #138496;
    color: white;
    text-decoration: none;
  }
}

/* --------------------------------------
 Handling multiple responses 
--------------------------------------- */
.multipleResponsesContainer {
  margin-top: 30px;
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 8px;

  h4 {
    margin-bottom: 15px;
    color: #495057;
  }
}

.responseNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover:not(:disabled) {
      background-color: #5a6268;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  span {
    font-size: 1.1em;
    font-weight: 600;
  }
}

/* --------------------------------------
 Toggling JSON or additional data
--------------------------------------- */
.toggleJsonButton {
  margin-top: 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #5a6268;
  }
}

.jsonDisplay {
  margin-top: 20px;
  background-color: #037df8;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 0.9em;
}

.jsonContainer {
  position: relative;
}

.copyJsonButton {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: none !important;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    color: inherit !important;
    transform: none !important;
  }
}

.copyJsonButton:hover {
  color: green;
}

/* --------------------------------------
 Existing user response notice 
--------------------------------------- */
.userResponseNotice {
  margin-bottom: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 20px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.25em;

  p {
    font-size: 1.5em;
    color: #495057;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }
}

.viewAddressHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

#startFreshButton,
#decryptEditButton,
#editButton,
#viewAnswersButton {
  margin-right: 10px;
  font-size: 1em;
  font-weight: 600;
  padding: 8px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

#startFreshButton {
  background-color: #28a745;
  border-color: #28a745;
  color: white;

  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
}

#decryptEditButton,
#editButton {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #212529;

  &:hover {
    background-color: #e0a800;
    border-color: #d39e00;
  }
}

#viewAnswersButton {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: white;

  &:hover {
    background-color: #138496;
    border-color: #117a8b;
  }
}

/* --------------------------------------
 Misc. binary / rating / freeform styling 
--------------------------------------- */
.freeformAnswer {
  font-style: italic;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 15px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}

.ratingAnswer {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;

  .ratingBar {
    height: 10px;
    background-color: #4DFFA4;
    border-radius: 5px;
    margin-right: 10px;
  }

  span {
    font-size: 1em;
  }
}

.multichoiceAnswer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;

  .choiceItem {
    background-color: #4DFFA4;
    color: #171941;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.9em;
  }
}

.binaryAnswer {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
}

.importanceSlider .rangeslider__fill {
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
}

/* For aggregator binary results */
.binaryOptionResults {
  display: flex;
  flex-direction: column;
}

.binaryOptionResponse {
  margin-left: 5px;
  font-weight: 600;
  font-size: 1.25em;
}

/* --------------------------------------
 (Optional) Mini-Create-Survey references 
--------------------------------------- */
#miniCreateSurveyLabel {
  font-weight: 600;
  font-size: 1.5em;
  color: black !important;
}

.tagsInput {
  color: black !important;
}

.surveyResultsOverride {
  color: #ffffff !important;
  font-size: 1.2em !important;
  margin-bottom: 5px;
}

.questionListCard {
  padding: 10px;
  background: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em !important;
}

.exportAndFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: 0.5px solid black;
  background: rgba(167, 166, 166, 0.217);
  padding: 5px;
  border-radius: 5px;
}

.filterBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
}

.exportDataBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 5px;
}

#exportOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* --------------------------------------
 Minimal progress bar styling
--------------------------------------- */
.miniBarContainer {
  display: flex;
  flex-direction: column;
  gap: 8px; /* spacing between bars if you have two */
  margin-top: 10px;
}

/* Each bar line (label + progress + fraction) in a row */
.miniBarLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 250px; /* or whatever suits */
  max-width: 400px;
  margin-right: 8px;
}

.miniBarLabel {
  margin-right: 6px;
  white-space: nowrap;
  font-size: 0.9rem;
  color: #333;
}

.miniProgress {
  flex: 1;
  margin-right: 6px;
  height: 10px;
  border-radius: 5px;
}

.miniBarFraction {
  font-size: 0.8rem;
  color: #555;
  white-space: nowrap;
}


#questionFilterButton {
  background: #171941;
  color: white;
  font-size: 1.15em;
  font-weight: 600;
}

#questionFilterIcon {
  margin-left: 10px;
}