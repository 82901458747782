// Variables for futuristic pastel styling
$pastel-bg: #f0faff;         // Light pastel background
$pastel-bg-hover: #e2f0f7;
$pastel-highlight: #b8f3ff;
$pastel-border: #80dfee;
$pastel-exclude: #ffb8c7;
$pastel-text: #333;
$pastel-darker: #91cdd9;

// For better contrast on text with pastel backgrounds:
$heading-color: #2a2a2a;
$text-color: #444;

// Additional styling
.questionFilterInline {
  margin: 1rem 0;
  padding: 1rem;
  background: $pastel-bg;
  border: 1px solid $pastel-border;
  border-radius: 6px;
  color: $text-color;

  @media (max-width: 600px) {
    padding: 0.75rem;
  }
}

.summaryContainer {
  background: lighten($pastel-bg, 3%);
  border: 1px solid $pastel-border;
  padding: 0.75rem;
  border-radius: 5px;
  margin-bottom: 1rem;

  .summaryTitle {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: $heading-color;
  }

  .noFiltersMsg {
    font-style: italic;
    color: #777;
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}

.filterSummaryContainer {
  background: lighten($pastel-bg, 3%);
  border: 1px solid $pastel-border;
  padding: 0.75rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.filterSummaryLabel {
  font-weight: 600;
  font-size: 1rem;
  color: $heading-color;
  margin-bottom: 0.5rem;
}

.filterSummaryCount {
  font-size: 0.95rem;
  color: $heading-color;
  margin-bottom: 1rem;
}

.summaryItemsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.filterBubble {
  display: inline-flex;
  align-items: center;
  background: $pastel-highlight;
  color: $text-color;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: background 0.2s;
  &:hover {
    background: darken($pastel-highlight, 3%);
  }
}

.removeIcon {
  margin-left: 6px;
}

.modalHeaderSummary {
  width: 100%;
  display: flex;
  flex-direction: column;
  .modalTitle {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    color: $heading-color;
  }
}

.modalHeader {
  border-bottom: 1px solid $pastel-border;
  padding: 1.25rem 1.5rem;
  background: $pastel-bg;

  :global(.modal-title) {
    font-size: 1.25rem;
    font-weight: 600;
    color: $heading-color;
  }
}

.modalBody {
  padding: 1.5rem;
  background: $pastel-bg;
  color: $text-color;
}

.modalFooter {
  border-top: 1px solid $pastel-border;
  padding: 1rem 1.5rem;
  background: $pastel-bg;
}

// Collapsible Section Styles
.filterSection {
  margin-bottom: 1.5rem;

  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background: lighten($pastel-bg, 3%);
    border: 1px solid $pastel-border;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: $pastel-bg-hover;
    }

    h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
      color: $heading-color;
      display: flex;
      align-items: center;
    }

    .icon {
      transition: transform 0.2s ease;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  .sectionContent {
    padding: 1rem;
    border: 1px solid $pastel-border;
    border-top: none;
    border-radius: 0 0 0.375rem 0.375rem;
    background: $pastel-bg;
    color: $text-color;
  }
}

.filterOption {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: $text-color;

  input[type="checkbox"] {
    margin-right: 0.75rem;
    cursor: pointer;
  }

  &:hover {
    color: $pastel-darker;
  }
}

.searchInput {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid $pastel-border;
  border-radius: 0.375rem;
  margin-bottom: 1rem;

  &:focus {
    outline: none;
    border-color: $pastel-darker;
    box-shadow: 0 0 0 2px rgba($pastel-darker, 0.1);
  }
}

// Popular Questions Badge
.popularBadge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background: rgba($pastel-highlight, 0.1);
  color: $text-color;
  border-radius: 1rem;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.disabledSection {
  opacity: 0.5;
  pointer-events: none;
}

.disabledText {
  color: gray;
  font-style: italic;
}

#topQuestionsCountInput {
  min-width: 70px !important;
  max-width: 70px !important;
  background: transparent !important;
  color: $text-color !important;
  font-size: 1.1em !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding: 5px !important;
}

.loadingContainer {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  p {
    margin: 0 0 0 0.75rem;
    font-size: 0.95rem;
  }
}

/* Tag bubble container + clickable #tag style */
.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tagBubble {
  background: #c4ffef;
  color: $text-color;
  padding: 0.3rem 0.6rem;
  border-radius: 15px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: darken(#c4ffef, 5%);
  }
}

.tagBubbleSelected {
  background: darken(#c4ffef, 10%);
  font-weight: 600;
}
