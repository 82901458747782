/* Base styling for the Survey Tool */
.surveyTool {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1f2251;
  padding: 20px;
  border-radius: 8px;
  box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;
}

#surveysRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #80808082;
  padding: 20px;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

#surveysDropdown {
  display: flex;
  flex-direction: row;
}


/* Styling for Survey Selector */

#surveySelectorRow {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}


.surveySelector {
  // width: 100%;
  margin-bottom: 20px;

  .dropdown-toggle {
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.25);
    background: #1f2251;
    border-color: white;

    &:hover {
      background: #272b65;
    }
  }

  .dropdown-menu {
    background: #1f2251;

    .dropdown-item {
      color: rgba(255, 255, 255, 0.25);

      &:hover {
        background: #272b65;
      }
    }
  }
}

#dropdownToggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  font-size: 1.45em;
  background-image: unset;
  border: 2px white solid;
  opacity: 0.9;
  border-radius: 10px;
}

#dropdownToggleCaret {
  font-size: 1.25em;
  margin-left: 10px;
}

#dropdownMenu {
  // width: 50%;
}

.dropdownItem {
  color: black !important;
  text-transform: uppercase;
  font-size: 1.5em !important;
  font-weight: 600 !important;
}

/* Styling for Survey Questions (general card/layout) */
.surveyQuestions {
  width: 100%;

  .questionCard {
    margin-bottom: 20px;
    background: #1f2251;
    border-radius: 8px;
    box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;

    .card-body {
      h5 {
        color: white;
        font-weight: 600;
        margin-bottom: 10px;
      }

      label {
        color: rgba(255, 255, 255, 0.25);
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  .binaryChoice {
    display: flex;
    justify-content: space-between;

    button {
      width: 48%;
    }
  }

  .slider {
    margin-top: 20px;
    max-width: 200px;

    .rangeslider__fill {
      background: #2dce89;
    }

    .rangeslider__handle {
      background: #1f2251;
      border: 2px solid #2dce89;
    }
  }

  .spectrum {
    // ... styles for spectrum ...
  }

  .multiChoice {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .option {
      flex: 1;
      margin: 5px;
      padding: 10px;
      border: 1px solid #272b65;
      border-radius: 4px;
      background: #1f2251;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: #272b65;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      color: white;
      background: #1f2251;
      border-color: white;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background: #272b6500;
      }
    }
  }
}


#surveySlider {
  max-width: 150px;
}

#responsePreview {
  color: white !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: transparent;
  // border: 1px white solid;
  font-size: 1em;
  border-radius: 5px;
  overflow: scroll;
  font-family: 'Courier New', Courier, monospace;
  margin: 10px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 0px;
  background: #171941;
  box-shadow:  5px 5px 10px #0d0e24,
              -5px -5px 10px #21245e;
              background-color: transparent;
              padding: 15px;
              margin-top: 10px;
              border-radius: 5px;
              overflow-x: auto;
}

#ratingInput {
    // margin-top: 20px;
    padding: 20px;
    margin-right: 20px;
    max-width: 200px;

    // .rangeslider__fill {
    //   background: #2dce89;
    // }

    // .rangeslider__handle {
    //   background: #1f2251;
    //   border: 2px solid #2dce89;
    // }
  }

#textInput {
  min-height: 100px;
}

#ratingLabelText {
margin-left: 10px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
font-weight: 600;
color: white;
font-size: 1.5em;
margin-right: 10px;
}

#multiChoice {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  font-size: 1.5em;
}


// ... (previous styles remain the same)

#binaryChoice {
display: flex;
flex-direction: row;
// justify-content: space-around;
align-items: center;
margin-top: 1rem;

.radioOptionText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: font-weight 0.3s ease;

  &.selected {
    font-weight: 900;
    font-style: italic;
  }

  input[type="radio"] {
    display: none;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &.agree {
    background-color: #4caf50;
    color: white;

    &:before {
      font-weight: 900;
      margin-right: 0.5rem;
    }
  }

  &.unsure {
    background-color: #ffeb3b;
  }

  &.disagree {
    background-color: #f44336;
    color: white;

    &:before {
      font-weight: 900;
      margin-right: 0.5rem;
    }
  }

  .optionIcon {
    margin-right: 0.5rem;
  }
}
}

.checkboxOptionText {
font-size: 0.9em !important;
font-weight: 600;
}

#surveyFooter {
display: flex;
flex-direction: row;
justify-content: center;
margin-top: 20px;
}

#submitSurveyButton {
font-size: 2em;
background: transparent;
font-weight: 600;
text-transform: uppercase;
border: white 1px solid;
  opacity: 0.7;
}

#encryptAdditionalThoughts {
// margin-bottom: 10px;
border: 0px transparent solid;
}

// Media queries for responsiveness on encrypt text
@media (min-width: 0px) and (max-width: 319px) {
#inputGroupTextBox {
  font-size: 1em;
}

#encryptIcon {
  font-size: 0.75em;
  margin-right: 5px;
}

#binaryChoice {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}
}

@media (min-width: 320px) and (max-width: 465px) {
#inputGroupTextBox {
  font-size: 1em !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  padding: 0 !important;
}

#encryptIcon {
  font-size: 1.5em !important;
  margin-right: 0 !important;
}

#responseInputSection {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

#binaryChoice {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
  gap: 7.5px;
}
}

@media (min-width: 466px) and (max-width: 768px) {
#inputGroupTextBox {
  font-size: 1em !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  padding: 0 !important;
}

#encryptIcon {
  font-size: 1.5em !important;
  margin-right: 0 !important;
}
}

@media (min-width: 769px) and (max-width: 1366px) {
#inputGroupTextBox {
  font-size: 1.25em !important;
  margin-left: 7.5px !important;
  margin-right: 7.5px !important;
  padding-left: 5px !important;
}
#encryptIcon {
  font-size: 1.5em !important;
  margin-right: 10px !important;
}
}

@media (min-width: 1367px) {
#inputGroupTextBox {
  font-size: 1.5em !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
#encryptIcon {
  font-size: 2em !important;
  margin-right: 15px !important;
}
}

// tree (JSON display)
.tree {
list-style-type: none;
margin: 0;
padding: 0;
color: white;

.treeItem {
  color: white;
  display: flex;
  flex-direction: row;

  .keyValueContainer {
    display: flex;
    align-items: center;
    justify-content: start;

    > span {
      margin-right: 5px;
    }
  }
}
}

// Card body styling
#questionTitleBody {
/* Keep if used for general question layout */
}

.questionTitleBody {
/* Likely specific to SurveyResults */
/* background-color: #0067ff; */ /* Commenting out - appears related to results display */
}

#questionTitle {
font-weight: 600;
font-size: 1.5em;
padding: 10px;
opacity: 0.8;
margin-bottom: 5px;
color: white !important;
}

.additionalCommentsSection {
display: flex;
flex-direction: column;
margin-top: 10px;
margin-bottom: 10px;
}

.additionalCommentsLabel {
margin-top: 10px;
font-size: 1.05em;
font-weight: 600;
}

.additionalCommentsContent {
opacity: 0.8;
font-weight: 600;
font-size: 1.15em;
}

.questionTitle {
font-weight: 600;
font-size: 1.5em;
padding: 10px;
opacity: 0.8;
}

#importanceText {
font-size: 1.25em;
opacity: 0.5;
margin-top: 10px;
}

// Encrypt button parts (used by AudioInput but might be general?) - Keep for now, AudioInput can import this file too
#encryptOptionButton {
display: flex;
flex-direction: row-reverse;
justify-content: space-evenly;
align-items: center;
background-color: #00048080;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
border-bottom-left-radius: 0px;
border-top-left-radius: 0px;
cursor: crosshair;
}

#encryptIcon {
font-size: 2em !important;
margin-right: 15px;
color: white;
opacity: 0.25;
}

#inputGroupTextBox {
border: 1px solid transparent;
border-radius: 0px;
font-size: 1.5em;
font-weight: 600;
text-transform: uppercase;
opacity: 0.5;
display: flex;
flex-direction: row;
justify-content: space-between;
margin-left: 10px;
margin-right: 10px;
}

#encryptCheckbox {
margin-top: 5px;
margin-bottom: 5px;
scale: 1.5;
margin-right: 10px;
}

#surveyButtonText {
display: inline;
flex-direction: row;
align-items: center;
}

// Slider styles (general)
#ratingSlider, .importanceSlider {
width: 200px;
}

// Tooltip styling (general utility)
.tooltip {
position: relative;
display: inline-block;
cursor: pointer;
margin-left: 5px;

.tooltipText {
  display: flex;
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  /* margin-left: -100px; */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1em;
  text-transform: none;
  margin-right: 5px;
  margin-left: 5px;
}

&:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
}

/* Component media queries */
@media (min-width: 0px) and (max-width: 319px) {
#encryptOptionButton {
  flex-direction: column !important;
}
}

@media (min-width: 320px) and (max-width: 465px) {
#encryptOptionButton {
  flex-direction: column !important;
  min-width: 105px;
}
}

@media (min-width: 466px) and (max-width: 768px) {
#encryptOptionButton {
  flex-direction: column !important;
}
}

@media (min-width: 769px) and (max-width: 1366px) {
/* Potentially adjust general layouts */
}

@media (min-width: 1367px) {
/* Potentially adjust general layouts */
}

/* Filter/Results Buttons (part of SurveySelector) */
#filterButton {
background: transparent;
font-weight: 600;
padding: 10px;
font-size: 1.5em;
color: white;
text-transform: uppercase;
border: white 1px solid;
border-radius: 10px;
opacity: 0.9;
}

#showResultsButton {
background: transparent;
font-weight: 600;
padding: 10px;
font-size: 1.5em;
color: white;
text-transform: uppercase;
border: white 1px solid;
border-radius: 10px;
opacity: 0.5;
}

#createSurveyButton { /* This ID is used in SurveySelector, keep it */
background: transparent;
font-weight: 600;
padding: 10px;
font-size: 1.5em;
color: white;
text-transform: uppercase;
border: white 1px solid;
border-radius: 10px;
opacity: 0.5;
}


/* Styles for the question filtering modal trigger/display (kept as it's related to the Filter button in SurveySelector) */
.questionsDashboard .modal-content {
background-color: white !important;
color: black !important;
font-size: 1.25em;
}

.questionsDashboard .modal-header,
.questionsDashboard .modal-footer {
border: none;
}

.questionsDashboard .modal-header .modal-title {
font-weight: bold;
}

.questionsDashboard .form-group label {
font-size: 1.25em;
}

.questionsDashboard .form-check-label {
font-size: 1.25em;
color: black !important;
font-weight: 600;
}

.questionsDashboard .form-check-input {
width: 1.5em;
height: 1.5em;
font-size: 1.25em;
color: black !important;
font-weight: 600;
}

.questionsDashboard .modal-footer .btn {
font-size: 1.25em;
font-size: 1.25em;
color: black !important;
font-weight: 600;
}

.questionsDashboard .modal-body {
max-height: 60vh;
overflow-y: auto;
}

/* Surveys row responsiveness */
@media (min-width: 466px) and (max-width: 768px) {
#surveysRow {
  flex-direction: column;
  gap: 15px;
}
}

@media (max-width: 466px) {
  #surveysRow {
    flex-direction: column;
    gap: 15px;
  }
}

/* Survey/Question Dropdown item styling */
.dropdownItem {
color: black !important;
text-transform: uppercase;
font-size: 1.5em !important;
font-weight: 600 !important;

&.questionsItem {
  background-color: #ffe6e6 !important; // pastel pink
  font-style: italic;
  
  &:hover, &.active {
    background-color: #ffcccc !important; // darker pastel pink
  }
}

&.surveyItem {
  background-color: #ffffd1 !important; // pastel yellow
  
  &:hover, &.active {
    background-color: #ffff99 !important; // darker pastel yellow
  }
}
}


/* Loading indicator */
.loadingContainer {
text-align: center;
margin-top: 20px;
font-size: 1.5em;
font-weight: 600;
color: white;
}

/* Response display styles (used in SurveyQuestions when viewing answers) */
.freeformAnswer {
font-style: italic;
background-color: rgba(255, 255, 255, 0.15);
border-radius: 5px;
padding: 15px;
color: #fff;
font-weight: bold;
margin-bottom: 5px;
margin-top: 5px;
}

.ratingAnswer {
display: flex;
align-items: center;
background-color: rgba(255, 255, 255, 0.15);
border-radius: 5px;
padding: 10px;
color: #fff;
font-weight: bold;

.ratingBar {
  height: 10px;
  background-color: #4DFFA4;
  border-radius: 5px;
  margin-right: 10px;
}

span {
  font-size: 1em;
}
}

.multichoiceAnswer {
display: flex;
flex-wrap: wrap;
gap: 5px;
background-color: rgba(255, 255, 255, 0.15);
border-radius: 5px;
padding: 10px;
color: #fff;
font-weight: bold;

.choiceItem {
  background-color: #4DFFA4;
  color: #171941;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
}
}

.binaryAnswer {
background-color: rgba(255, 255, 255, 0.15);
border-radius: 5px;
padding: 10px;
color: #fff;
font-weight: bold;
}

.importanceSlider .rangeslider__fill {
background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
}

/* JSON Preview Copy Button (General Utility) */
.jsonContainer {
position: relative;
}

.copyJsonButton {
position: absolute !important;
top: 10px !important;
right: 10px !important;
background: transparent !important;
border: none !important;
cursor: pointer !important;
transition: none !important;

// Ensure link inside the button also inherits the style
a.copyJsonButton {
  color: black !important;
  font-size: 1.5em !important;
  text-decoration: none !important; // Ensures no underline on links
}

// Ensures all pseudo-elements have no hover or focus effects
&:hover, &:active, &:focus {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: inherit !important; // No color change on focus or hover
  transform: none !important;
}
}


.copyJsonButton:hover {
color: green;
}


/* Styles for the existing user response notice */
.userResponseNotice {
margin-bottom: 20px;
background-color: #e9ecef;
border-radius: 8px;
border: 1px solid #ced4da;
padding: 20px;
border-radius: 10px;
margin-left: 15px;
margin-right: 15px;
font-size: 1.25em;

p {
  font-size: 1.5em;
  color: #495057;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: 10px;
}
}

.viewAddressHeading {
display: flex;
align-items: center;
justify-content: center;
margin-top: 10px;
margin-bottom: 10px;
font-weight: 600;
}

/* Buttons within userResponseNotice */
#startFreshButton, #decryptEditButton, #editButton, #viewAnswersButton, #answerSurveyButton, #updateResponseButton, #exitEditingButton {
margin-right: 10px;
font-size: 1em;
font-weight: 600;
padding: 8px 15px;
border-radius: 5px;
transition: all 0.3s ease;
}

#startFreshButton {
background-color: #28a745;
border-color: #28a745;
color: white;

&:hover {
  background-color: #218838;
  border-color: #1e7e34;
}
}

#decryptEditButton, #editButton {
background-color: #ffc107;
border-color: #ffc107;
color: #212529;

&:hover {
  background-color: #e0a800;
  border-color: #d39e00;
}
}

#viewAnswersButton, #answerSurveyButton { /* Combined as they serve similar toggle purpose */
background-color: #17a2b8;
border-color: #17a2b8;
color: white;

&:hover {
  background-color: #138496;
  border-color: #117a8b;
}
}

#answerSurveyButton {
display: flex;
flex-direction: row;
align-items: center;
color: white;
font-size: 1.5em;
font-weight: 600;
background-color: #2963ca; /* Different color to distinguish */
}

#updateResponseButton {
background-color: #007bff;
border-color: #007bff;
color: white;
&:hover {
  background-color: #0056b3;
  border-color: #004085;
}
}

#exitEditingButton {
background-color: #6c757d;
border-color: #6c757d;
color: white;
&:hover {
  background-color: #5a6268;
  border-color: #545b62;
}
}


#uploadingEncryptingText {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
color: white;
}

#decryptQuestionButton {
background-color: #ffc107;
border-color: #ffc107;
color: #212529;
}

/* Response input section */
#responseInputSection {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}


#copySurveyIdIcon {
font-size: 1.5em;
margin-left: 10px;
margin-right: 10px;
}

/* Survey ID Preview */
.surveyIdContainer {
display: flex;
align-items: center;
justify-content: center;
margin-top: 10px;
font-weight: 600;
color: grey;
opacity: 0.75;
}

.surveyIdText {
font-size: 1.25em;
margin-left: 5px;
margin-right: 5px;
}

.copySurveyIdButton { /* Class used in SurveySelector */
font-size: 1.25em;
background: none;
border: none;
padding: 0;
cursor: pointer;
margin-right: 5px;
}