$primary-color: #00adb5;
$secondary-color: #393e46;
$background-color: #222831;
$text-color: #eeeeee;
$highlight-color: #00f3ff59;
$green-color: #00ff00;

.sbtPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: transparent;
  color: $text-color;
  font-family: 'Share Tech Mono', monospace;
  position: relative;

  .sbtInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    border: 1px solid $secondary-color;
    border-radius: 8px;
    overflow: hidden;
    background-color: $secondary-color;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

    .leftColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      .bookmarkIcon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
        background-color: $background-color;
        border-bottom: 2px solid $highlight-color;

        .bookmarkButton {
          background: none;
          border: none;
          color: $primary-color;
          cursor: pointer;
          margin-right: 10px;
        }

        .copyButton {
          background: none;
          border: none;
          color: $primary-color;
          cursor: pointer;
          margin-left: 10px;
        }

        .contractLink {
          margin-left: 10px;
          color: $primary-color;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .image {
        width: 100%;
        height: 300px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-bottom: 2px solid $highlight-color;
        }
      }

      .description {
        padding: 20px;
        width: 100%;
        flex-wrap: wrap;
        overflow: auto;
        justify-content: center;
        align-items: center;
        word-wrap: break-word;
        white-space: pre-wrap;

        h1 {
          margin-bottom: 10px;
          font-size: 28px;
          color: $primary-color;
        }

        p {
          margin: 5px 0;
          font-size: 16px;
          color: $text-color;
        }
      }
    }

    .rightColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      padding: 20px;

      .statsSection, .actionSection, .moreDetailsSection {
        margin-bottom: 20px;
        width: 100%;
      }

      .actionsSection {
      }

      .sectionHeader {
        color: white;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(45deg, $primary-color, $highlight-color);
        min-width: 200px;
        padding: 10px 15px;
        border-radius: 10px;
        transition: background 0.3s ease;

        &:hover {
          background: linear-gradient(45deg, $highlight-color, $primary-color);
        }

        .fa-chevron-up, .fa-chevron-down {
          margin-left: 10px;
        }
      }

      .stats {
        p {
          font-size: 18px;
          color: $text-color;

          .label {
            font-weight: bold;
            color: $green-color;
          }

          button {
            background: none;
            border: none;
            color: $primary-color;
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;

    .actionButton {
      padding: 10px 20px;
      margin: 5px;
      font-size: 16px;
      color: $text-color;
      background-color: $primary-color;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      width: 120px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        background-color: #555;
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background-color: $highlight-color;
      }
    }
    #mintButtonArea {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .mintButton, .burnButton {
      font-size: 18px;
    }

    .countdown {
      margin-left: 10px;
      font-size: 14px;
      color: $highlight-color;
    }

    .input {
      padding: 8px;
      margin-right: 10px;
      font-size: 16px;
      border: 1px solid $secondary-color;
      border-radius: 4px;
      color: $text-color;
      background-color: $background-color;
    }
  }

  .error {
    color: red;
  }

  .createGroupButtonContainer {
    margin-top: 20px;

    #createSurveyButton {
      padding: 10px 20px;
      font-size: 16px;
      color: $text-color;
      background-color: $highlight-color;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;

      &:hover {
        background-color: lighten($highlight-color, 10%);
      }

      .tooltip {
        margin-left: 10px;
        position: relative;

        .tooltipText {
          visibility: hidden;
          width: 300px;
          background-color: $primary-color;
          color: $text-color;
          text-align: center;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 125%;
          left: 50%;
          margin-left: -150px;
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:hover .tooltipText {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;

    .modalContent {
      background-color: $secondary-color;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      max-width: 500px;
      width: 100%;

      h2 {
        color: $highlight-color;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding: 0;
        max-height: 300px;
        overflow-y: auto;

        li {
          padding: 5px 0;
          color: $text-color;
        }
      }

      .closeButton {
        padding: 10px 20px;
        font-size: 16px;
        color: $text-color;
        background-color: $highlight-color;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }

  .moreDetails {
    padding: 15px;
    background-color: rgba($secondary-color, 0.5);
    border-radius: 8px;
    margin-top: 10px;

    p {
      margin-bottom: 10px;
      color: $text-color;
    }
  }

  .passwordAlert {
    margin-bottom: 20px;
  }
}

.backButton {
  background: transparent;
  font-weight: 600;
  padding: 15px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
  margin-bottom: 20px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}

#mintingAddressModal {
  background: white !important;

  .mintingAddressList {
    list-style-type: none;
    padding: 0;
  }

  .mintingAddressItem {
    color: black !important;
    padding: 5px 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// miniaturized version of the SBTItem component
.sbtItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 200px;
  max-width: 200px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: solid 2px black;
  background: #c2c9c980;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  #miniSbtName {
    font-size: 1.25rem;
    font-weight: 700;
    color: $text-color;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  #miniSbtAddress {
    font-size: 1.1rem !important;
    font-weight: 600;
    color: $highlight-color;
    cursor: pointer;
    opacity: 0.55 !important;
  }

  p {
    margin: 2px 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.mintButton {
  .fa-lock {
    margin-left: 10px;
  }
}

.passwordEntry {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 10px;
    margin-bottom: 10px;
}

.mintProcess {
  display: flex;
  flex-direction: column;
  align-items: center;

  .passwordLoaded {
    margin-bottom: 10px;
    color: #28a745;
    font-weight: bold;
  }

  .mintCountdown, .claimCountdown {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
  }

  .mintSuccess {
    color: #28a745;
    font-weight: bold;
    font-size: 1.2em;
  }
}

.progressIndicator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  .stepCompleted, .stepInProgress {
    flex: 1;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
  }

  .stepCompleted {
    background-color: #28a745;
    color: white;
  }

  .stepInProgress {
    background-color: #ffc107;
    color: black;
  }
}

.adminActions {
  margin-top: 20px;
  padding: 15px;
  background-color: #2c2f33;
  border-radius: 5px;
  margin-bottom: 20px; 
  border: 1px solid #444;
  
  h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    text-align: center;
    color: #00ff9d;
  }

  .adminBurnSection, .inviteGenerationSection {
    margin-bottom: 15px;
    // background: #23272a;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #333;

    h4 {
      margin-bottom: 10px;
      color: #f0f0f0;
      font-size: 1.1em;
    }

    .burnInputGroup, .inviteGenerationControls {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;

      .input {
        margin-right: 10px;
        padding: 5px;
        border-radius: 3px;
        border: 1px solid #555;
        background: #1e2124;
        color: #ddd;
        width: 100%;
        max-width: 250px;
      }

      .actionButton {
        background: #00ff9d;
        border: none;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        border-radius: 3px;
        padding: 5px 10px;
        margin-left: 10px;
        &:hover {
          background: #00dda0;
        }
      }

      .burnSearchResult {
        margin-left: 10px;
        p {
          margin: 0;
          font-size: 0.9em;
        }
      }
    }

    .generatedPasswordsList {
      margin-top: 10px;
      ul {
        padding-left: 20px;
        li {
          color: #ddd;
        }
      }
    }
  }
}


.relevantInfo {
  h3 {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: 5px;
    }
  }
}

.passwordAlert {
  margin-bottom: 20px;
}

#miniSbtName{
  text-align: center;
} 

#miniSbtAddress {
  // word-break: break-all;
  text-align: center;
  // opacity: 0.5;
  font-size: 1em;
}


.miniImageContainer {
  position: relative;
}

.iconOverlay {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  /* align-items: center; */
  align-content: center;
  justify-content: center;
  width: 100%;
}

.lockIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #ffffff;
}

.liveIndicator {
  width: 10px;
  height: 10px;
  background: #00ff00;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
}

@media (max-width: 768px) {
  .sbtPage {
    padding: 10px;

    .sbtInfo {
      flex-direction: column;

      .leftColumn, .rightColumn {
        width: 100%;
      }

      .description {
        padding: 10px;

        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .actions {
      flex-direction: column;

      .actionButton {
        width: 100%;
        margin-bottom: 10px;
      }

      .input {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  #createSurveyButton {
    padding: 10px;
  }

  #showResultsButton {
    padding: 10px;
  }
}

.passwordAlert {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  background-color: $primary-color;
  color: $text-color;
  border-radius: 5px;
  font-size: 1.5em;
}

//

.docIDsSection {
  margin-top: 15px;
  h4 {
    margin-bottom: 10px;
    color: #00ff9d;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      margin-bottom: 5px;
      a {
        color: #007bff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.tagsSection {
  margin-top: 15px;
  h4 {
    margin-bottom: 10px;
    color: #00ff9d;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      margin-bottom: 5px;
      a {
        color: #007bff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.loading {
  color: white;
}

//

// for mini mintable version
#mintButtonArea {
  display: flex;
      margin-top: 10px;
      flex-direction: column;
}