// FILE: my-app/client/src/components/CommunityTab/CommunityTab.module.scss

.communityTab {
  display: flex;
  background: #1f2251;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 500px;

  .sectionTitle {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 15px;
    font-family: 'Share Tech Mono', monospace;
    text-transform: uppercase;
    opacity: 0.3;
    color: white;
  }

  .content {
    flex-grow: 1;
    overflow: auto;
  }

  .leaderboardSection {
    flex: 1;
    padding: 20px;
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.1);
  }

  .rightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .statsSection, .recognitionSection {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.1);
  }

  .statsSection {
    border-bottom: 1px solid #ccc;
  }

  .leaderboard {
    display: flex;
    flex-direction: column;
    // gap: 10px;
  }

  .leaderboardHeader {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    font-weight: bold;
    color: white;
  }

  .leaderboardItem {
    display: flex;
    align-items: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
    color: white;
    margin-top: 10px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .name {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .simBadge {
    color: yellow;
    margin-left: 5px;
    font-size: 0.8em;
  }

  .karma {
    font-weight: bold;
  }

  .groupsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }

  .groupItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
    color: white;

    &:hover {
      transform: scale(1.05);
    }
  }

  .groupLogo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  .switchButton {
    display: block;
    margin: 15px auto 0;
    padding: 8px 16px;
    background: #00adb5;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background 0.2s;
    font-size: 0.9em;

    &:hover {
      background: #00848c;
    }
  }

  .showMoreButton {
    display: block;
    margin: 15px auto 0;
    padding: 8px 16px;
    background: #00adb5;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background 0.2s, opacity 0.2s;
    font-size: 0.9em;
    opacity: 0.5;

    &:hover {
      background: #00848c;
      opacity: 0.7;
    }
  }

  .statsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: 15px;
    align-content: center;
    height: 100%;
  }

  .statItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
    color: white;

    &:hover {
      transform: scale(1.05);
    }
  }

  .statIcon {
    opacity: 0.5;
  }

  .statCount {
    font-size: 1.2em;
    font-weight: bold;
    margin: 5px 0;
  }

  .statLabel {
    font-size: 0.8em;
    opacity: 0.5;
  }

  .noIndividuals {
    text-align: center;
    color: white;
    padding: 20px;
  }

  .toggleLabel {
    display: flex;
    align-items: center;
    color: white;
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .toggleCheckbox {
    margin-right: 10px;
  }

  .noUsers {
    text-align: center;
    color: white;
    padding: 20px;
    font-style: italic;
  }
}

.modal {
  .modalHeader {
    background-color: #0083d9cc !important;
    color: white !important;
    border-bottom: 1px solid #dee2e6;
    font-size: 1.25em;

    h5 {
      font-size: 1.25em;
      color: white !important;
      font-weight: 600;
    }

    button {
      color: white;
      font-size: 1.5em;
      background-color: white;
    }
  }

  .modalBody {
    background-color: #0083d9cc !important;
    color: black !important;
  }

  .groupModalContent { /* Wrapper for group modal content */
    text-align: center;
  }

  .groupLogoModal { /* Specific style for logo in group modal */
    display: block;
    max-width: 150px; /* Added */
    max-height: 150px; /* Added */
    width: auto; /* Allow aspect ratio to maintain */
    height: auto; /* Allow aspect ratio to maintain */
    object-fit: contain; /* Added */
    margin: 0 auto 20px auto; /* Centering and margin */
  }

  .groupDescriptionModal { /* Specific style for description in group modal */
    font-size: 1.5em; /* Added */
    font-weight: 600; /* Added */
    color: black; /* Ensure text is readable on modal background */
    margin-bottom: 20px;
    text-align: justify; /* Or center, depending on preference */
  }

  .groupLinksModal { /* Specific style for links in group modal */
    margin-top: 20px;
    text-align: left; /* Align links to the left */
    background: white;
    padding: 10px;

    h4 {
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 10px;
      color: black;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 8px;
    }

    a {
      color: #0056b3; /* Link color */
      text-decoration: none;
      font-weight: 500;
      display: inline-flex; /* Use flex to align icon and text */
      align-items: center; /* Vertically align icon and text */
      gap: 8px; /* Space between icon and text */

      &:hover {
        text-decoration: underline;
        color: #003d80;
      }

      svg { /* Style the FontAwesome icon */
        opacity: 0.7;
      }
    }
  }
}

@media (max-width: 768px) {
  .communityTab {
    flex-direction: column;
    height: auto;

    .leaderboardSection, .rightSection {
      width: 100%;
    }

    .leaderboardSection {
      border-right: none;
      border-bottom: 1px solid #ccc;
    }

    .statsSection, .recognitionSection {
      height: auto;
    }
  }
}

//

.modal {
  max-width: 800px;
  margin: 30px auto;

  :global(.modal-content) {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  :global(.modal-header) {
    background: #f8f9fa;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 20px 30px;

    .modal-title {
      font-size: 24px;
      font-weight: 600;
      color: #1a1a1a;
    }
  }

  :global(.modal-body) {
    padding: 30px;

    .userList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-height: 70vh;
      overflow-y: auto;

      .userItem {
        display: flex;
        align-items: center;
        padding: 12px;
        background: #b9164b; /* Lighter blue background */
        border-radius: 8px;
        // transition: background 0.2s;
        color: #333; /* Darker text for contrast */
        cursor: pointer; /* Add pointer cursor */

        &:hover {
          background: #e0f0ff; /* Slightly darker blue on hover */
        }

        // Keeping avatar styles commented as they are not in the current JSX structure
        // .userAvatar {
        //   width: 40px;
        //   height: 40px;
        //   border-radius: 50%;
        //   margin-right: 12px;
        //   background: #e0e0e0;
        // }

        // .userInfo {
        //   flex: 1;

        //   .userName {
        //     font-weight: 600;
        //     color: #1a1a1a;
        //     margin-bottom: 4px;
        //   }

        //   .userAddress {
        //     color: #666;
        //     font-size: 14px;
        //   }
        // }
      }
    }

    .surveyList {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .surveyItem {
        padding: 16px;
        background: #f8f9fa;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .surveyLink {
          font-size: 18px;
          color: #1a1a1a;
          text-decoration: none;
          font-weight: 500;
          flex: 1;

          &:hover {
            text-decoration: underline;
          }
        }

        .questionCount {
          background: #e9ecef;
          padding: 6px 12px;
          border-radius: 16px;
          color: #495057;
          font-size: 14px;
          margin-left: 16px;
        }
      }
    }

    .questionContent { // Renamed from questionContent for clarity
      text-align: center;
      padding: 40px;
      font-size: 18px;
      color: #1a1a1a;

      a {
        color: #0066cc;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}


.userList {
  max-height: 400px; // Adjust as needed
  overflow-y: auto;
  padding: 10px;
}

.userItem {
  padding: 8px 12px; /* Increased padding */
  border-bottom: 1px solid #eee; /* Lighter border */
  background-color: #f0f8ff; /* Light blue background */
  color: #333; /* Darker text */
  font-weight: 600;
  border-radius: 6px; /* Added border-radius */
  margin-bottom: 5px; /* Space between items */
  cursor: pointer; /* Indicate clickable */
  transition: background-color 0.2s ease; /* Smooth hover effect */

  &:hover {
      background-color: #d6eaff; /* Slightly darker blue on hover */
  }
}


//

.questionsCount,
.responsesCount {
  display: inline-block;
  margin-left: 10px;
  font-size: 0.9rem;
  color: #4a90e2; // Nice blue for visibility and contrast
  background-color: #f0f8ff; // Light background for subtle contrast
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    color: #ffffff; // White text on hover
    background-color: #007aff; // Slightly darker blue
    cursor: pointer;
  }
}

.surveyLink {
  color: #333; // Dark gray for a minimalistic look
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #007aff; // Consistent blue highlight
    text-decoration: underline;
  }
}

.surveyItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #eaeaea; // Subtle border for separation
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #ffffff; // White background for a clean look
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Soft shadow for depth
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); // More pronounced shadow on hover
  }
}

.userList,
.surveyList {
  max-height: 400px; // Limit height to ensure no overflow
  overflow-y: auto;
  padding-right: 10px;

  /* Add a scrollbar style */
  &::-webkit-scrollbar { /* Target WebKit browsers */
    width: 6px;
  }

  &::-webkit-scrollbar-thumb { /* Target WebKit browsers */
    background: #007aff; // Blue scroll thumb
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track { /* Target WebKit browsers */
    background: #f0f0f0; // Light gray track
  }

   /* Standard scrollbar styles (optional, for Firefox/IE) */
   scrollbar-width: thin; /* Firefox */
   scrollbar-color: #007aff #f0f0f0; /* Firefox - thumb and track */
}

// Keeping this redundant userItem style commented out as the one above is more specific
// .userItem {
//   display: flex;
//   align-items: center;
//   padding: 8px 12px;
//   border-radius: 6px;
//   background-color: #f0f8ff; // Light background for each item
//   margin-bottom: 5px;
//   font-size: 0.95rem;
//   color: #333;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #d6eaff; // Highlighted light blue
//     cursor: pointer;
//   }
// }

//

#seeQuestionsText {
  font-size: 1.5em;
  font-weight: 600;
}

#seeQuestionsLinkText {
  font-size: 1em;
  font-weight: 600;
}