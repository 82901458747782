.onePageDemoContainer {
    /* Increase base font to make text bigger overall */
    font-family: sans-serif;
    font-size: 1rem; 
    line-height: 1.6;
    color: #1a1a1a;
  
    /* Light overall background (slightly tinted) so it doesn't conflict with main site color */
    background-color: rgba(145, 112, 222, 0.08);
    padding: 20px;
  }
  
  /* Top header/branding area */
  .brandingSection {
    /* Lighter violet background so logo is visible */
    background-color: rgba(168, 116, 255, 0.2);
    border: 1px solid #bfa0f4;
    border-radius: 8px;
    padding: 20px;
  
    /* FLEX layout: blurb on left, image on right */
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 0px; /* space between left blurb & right image */
    flex-direction: column;
  }
  
  /* Make the Foresight logo bigger */
  .brandLogo {
    max-width: 350px; 
    margin-bottom: 0px;
  }
  
  /* Flex item for the text portion */
  .brandText {
    flex: 1 1 auto; /* allow it to expand */
    font-size: 1.3rem;
    line-height: 1.5;
  }
  
  /* Flex item for the header image portion */
  .brandImage {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .headerImage {
    max-width: 300px;
    margin-top: 12px;
    margin-bottom: 0px;
  }
  
  .eventBlurb {
    font-size: 1.25rem;
    line-height: 1.2;
    padding: 15px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  #blurbText {
    line-height: 1.6;
    padding: 20px;
    font-weight: 500;
    font-size: 1em;
    margin: 20px;
  }
  
  /* Each collapsible section wrapper (Questions, Groups, Results) */
  .sectionContainer {
    margin-bottom: 20px;
    margin-top: 20px;
    // background-color: rgba(168, 116, 255, 0.08);
    background-color: #5c58a630;
    border: 1px solid #af95db;
    padding: 20px;
    border-radius: 6px;
  }
  
  /* Section header text: bigger and bold */
  .sectionHeader {
    display: flex !important
;
            flex-direction: row;
            align-items: center;
    cursor: pointer;
    font-size: 2rem;
    margin: 0;
    display: inline-block;
    font-weight: bold;
    color: white;
    opacity: 0.75;
  }
  
  /* The content area inside each collapsible section */
  .miniSectionContent {
    margin-top: 15px;
    background: transparent;
    // border: 1px solid #bfa0f4;
    padding: 15px;
    border-radius: 4px;
  }
  
  /* (Optional) older placeholder classes if needed */
  .surveySection {
    background: transparent;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 6px;
  }
  .groupsSection {
    background: transparent;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 6px;
  }
  .miniSbtArea {
    margin-top: 10px;
    background: rgba(168, 116, 255, 0.05);
    border: 1px solid #bfa0f4;
    padding: 10px;
    border-radius: 4px;
  }
  
  /* Torus modal classes if still needed */
  .torusModalBackdrop {
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(0,0,0,0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .torusModal {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 320px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    text-align: center;
    color: #111;
  }
  
  /* ------------------------------------
     NEW: Sub-title class with 0.5 opacity
  ------------------------------------- */
  .subTitleDim {
    opacity: 0.5;
  }
  
  /* --------------------------------------------
     NEW: Additional classes for "About" collapsible
  --------------------------------------------- */
  .aboutContainer {
    display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background: #00ff1b80;
        border-radius: 7px;
        margin-right: 15px;
        margin-left: 15px;
        padding: 20px;
        color: white;
        font-size: 1.65em;
        margin-bottom: 15px;
        margin-top: 15px;
        font-weight: 600;
        border: 1px #171941 solid;
        border-radius: 5px;
        box-shadow: 5px 5px 12px #0f102a, -5px -5px 12px #1f2258;
  }

  .aboutHeader {
    display: flex
    ;
        flex-direction: row-reverse;
        align-self: center;
        opacity: 0.75;
        font-weight: 600;
        color: white;
        align-items: center;
        text-transform: uppercase;
        font-size: 1.2em;
  }

  .aboutContent {
    font-size: 0.85em;
    font-weight: 700;
    color: white;
  }

  #aboutText {
    font-weight: 600;
  }

  #aboutArrowIcon {
    font-size: 2em;
    margin-right: 20px;
  }
  
  /* -------------------------------------------
     Responsive adjustments for smartphones
     (example @media query for max-width: 600px)
  ------------------------------------------- */
  @media only screen and (max-width: 600px) {
    .onePageDemoContainer {
      font-size: 1rem;
      padding: 10px;
    }
  
    .brandingSection {
      flex-direction: column;
      padding: 15px;
    }
  
    .brandLogo {
      max-width: 250px;
      margin-bottom: 0px;
    }
  
    .eventBlurb {
      flex-direction: column;
      align-items: center;
      padding-top: 0px;
    }
  
    #blurbText {
      margin: 10px 0;
      font-size: 0.95rem;
      padding: 0;
    }
  
    .sectionContainer {
      padding-left: 0px;
      padding-right: 0px;
      border: none;
    }
  
    .sectionHeader {
      font-size: 1.35em;
      padding-left: 10px;
    }
  
    .miniSectionContent {
      padding: 10px;
    }
  
    .headerImage {
      max-width: 200px;
      margin-top: 8px;
     margin-bottom: 8px;
    }
  }
  
  /* -------------------------------------------
     NEW: Additional mid-range (tablet) styling
     Adjust so it doesn't mirror the phone layout
  ------------------------------------------- */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .onePageDemoContainer {
      font-size: 1.15rem;
      padding: 15px 25px;
    }
  
    .brandingSection {
        flex-direction: column;
        align-items: center;
        grid-gap: 15px;
        gap: 0px;
    }
  
    .brandLogo {
      max-width: 280px;
      margin-bottom: 0px;
    }
  
    .eventBlurb {
      padding: 10px;
      font-size: 1.1rem;
      flex-direction: column;
    }
  
    #blurbText {
        margin: 0px;
        font-size: 1.2rem;
        padding: 10px;
    }
  
    .headerImage {
      max-width: 250px;
      margin-top: 10px;
     margin-bottom: 10px;
    }
  
    .sectionHeader {
      font-size: 1.6em;
    }
  
    .miniSectionContent {
      padding: 12px;
    }
  }
  

  .sectionToggleIcon {
    font-size: 1.5em;
    margin-right: 10px;
  }