/* ThreeDMatrix.module.scss */

/* --- Base & Container (Dark Theme) --- */
:global(body) {
  /* Assuming the dark background is set on body or a parent container */
  /* background-color: #1a1a2e; */ /* Example dark blue/purple */
  /* color: #e0e0e0; */          /* Light default text */
}

.container {
  perspective: 1000px !important; /* Enables 3D transforms for child elements */
  margin-top: 20px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  color: #e0e0e0 !important; /* Container text remains light */
}

/* --- Grid Containers (Dark Theme) --- */
.gridContainer,
.subgridContainer {
  display: grid !important;
  gap: 1px !important; /* Slightly smaller gap */
  background-color: #666 !important; /* Grid lines color */
  padding: 1px !important;
  border: 1px solid #555 !important;
  margin-bottom: 30px !important;
}

.subgridContainer {
  margin-top: 20px !important;
}

/* --- Base Cell Style (Dark Theme) --- */
.cell {
  background-color: #3a3a4a !important; /* Darker cell background */
  color: #e0e0e0 !important; /* Light text */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  min-height: 50px !important; /* Increased min-height slightly */
  padding: 4px 6px !important; /* Adjusted padding */
  position: relative !important;
  transition: transform 0.25s ease-out, box-shadow 0.25s ease-out, background-color 0.2s ease, outline 0.1s ease-out !important;
  cursor: default !important;
  border: 1px solid #555 !important; /* Darker border */
  overflow: hidden !important;
  font-size: 0.8em !important; /* Further reduced font size for headers */
  line-height: 1.2 !important; /* Improve line height for wrapped text */
  word-break: break-word !important; /* Allow words to break */
}

/* --- Header Cells (Dark Theme) --- */
.headerCell {
  cursor: pointer !important;
  font-weight: bold !important;
  background-color: #4a4a5a !important; /* Slightly lighter dark shade for headers */
  min-width: 80px !important; /* Ensure minimum width for headers */

  &:hover {
    background-color: #5a5a6a !important;
    z-index: 5 !important;
  }
}

.activeHeaderCell {
  background-color: #007bff !important; /* Use primary color for active */
  color: white !important;
  font-weight: bold !important;
  border-color: #0056b3 !important;
  z-index: 3 !important;
}

/* --- Data Cells (Dark Theme) --- */
.gridCell {
   cursor: pointer !important;
}

/* --- Highlighting (Dark Theme) --- */
.highlighted {
  /* Use a background overlay for highlighting */
  background-image: linear-gradient(rgba(0, 123, 255, 0.25), rgba(0, 123, 255, 0.25)) !important;
  background-blend-mode: overlay !important;
  /* outline: 2px solid rgba(0, 123, 255, 0.7) !important; */ /* Keep outline as alternative */
  /* outline-offset: -2px !important; */
  z-index: 2 !important;
}

/* --- 3D Pop-out Effect (Dark Theme) --- */
.popOutEffect {
  transform: translateZ(15px) scale(1.06) !important;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.6) !important; /* Darker shadow for dark theme */
  z-index: 10 !important;
  border-color: #aaa !important; /* Lighter border when popped out */
  background-color: #5a5a6a !important; /* Slightly lighter background when popped */
}

/* --- Specific Cell States (Dark Theme) --- */
.emptyCell {
  background-color: #303040 !important; /* Very dark, slightly distinct empty cell */
}

.diagonalCell {
  background-color: #2a2a3a !important; /* Darker, distinct diagonal */
  cursor: not-allowed !important;
  font-style: italic !important;
  color: #888 !important; /* Dimmer text */
}

/* --- Subcategory Selectors (Dark Theme) --- */
.subcategoryButtons {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 8px !important;
    margin-bottom: 15px !important;
}

.subcategoryButtons .btn {
    transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease !important;
    padding: 0.25rem 0.5rem !important;
    font-size: 0.8rem !important;
}
/* Button variants for dark background */
.subcategoryButtons .btn-secondary {
  background-color: #5a5a6a !important;
  border-color: #555 !important;
  color: #e0e0e0 !important;
  &:hover {
    background-color: #6a6a7a !important;
  }
}
.subcategoryButtons .btn-light {
   background-color: #4a4a5a !important;
   border-color: #555 !important;
   color: #ccc !important;
    &:hover {
      background-color: #5a5a6a !important;
   }
}

/* ====================================================== */
/* --- Modal Styles (Light Theme - White BG/Black Text) --- */
/* --- CAUTION: Using !important extensively           --- */
/* ====================================================== */

.riskMatrixCommentModal .modal-content {
    background-color: #ffffff !important; /* White modal background */
    color: #212529 !important; /* Default dark text (Bootstrap standard) */
    border: 1px solid rgba(0, 0, 0, 0.2) !important; /* Standard modal border */
    border-radius: 0.3rem !important; /* Standard Bootstrap modal radius */
}

.riskMatrixCommentModal .modal-header {
  background-color: #f8f9fa !important; /* Light grey header (Bootstrap standard) */
  color: #212529 !important; /* Dark header text */
  border-bottom: 1px solid #dee2e6 !important; /* Standard light border */
  padding: 1rem 1rem !important; /* Standard padding */
}

/* Reset close button style for light background */
.riskMatrixCommentModal .modal-header .btn-close {
  filter: none !important; /* Remove dark theme filter */
  /* Use default Bootstrap styling - !important not needed here typically */
}
.riskMatrixCommentModal .modal-header .modal-title {
    font-weight: 500 !important; /* Slightly bolder title */
}

.riskMatrixCommentModal .modal-body {
  background-color: #ffffff !important; /* White body */
  color: #212529 !important; /* Dark text */
  padding: 1rem !important; /* Standard padding */
}

.riskMatrixCommentModal .modal-body h5 {
    margin-top: 1rem !important;
    margin-bottom: 0.75rem !important; /* Adjusted spacing */
    font-weight: 500 !important; /* Standard heading weight */
    color: #212529 !important; /* Ensure dark heading text */
}
.riskMatrixCommentModal .modal-body h5:first-child {
    margin-top: 0 !important;
}

/* Existing Comments List (Light Theme) */
.riskMatrixModalCommentList {
    list-style: none !important;
    padding: 10px !important;
    margin-bottom: 1rem !important;
    max-height: 150px !important;
    overflow-y: auto !important;
    background-color: #f8f9fa !important; /* Light grey background for list */
    border: 1px solid #dee2e6 !important; /* Standard light border */
    border-radius: 0.25rem !important; /* Standard radius */
}

.riskMatrixModalCommentItem {
  background-color: #ffffff !important; /* White background for each item */
  color: #212529 !important; /* Dark text */
  margin-bottom: 8px !important;
  padding: 8px 12px !important; /* Slightly more padding */
  border-radius: 0.25rem !important;
  border-left: 3px solid #0d6efd !important; /* Bootstrap primary blue indicator */
  font-size: 0.9em !important;
  word-break: break-word !important;
  border: 1px solid #eee !important; /* Subtle border */
}
.riskMatrixModalCommentItem strong {
    text-transform: capitalize !important;
    color: #0d6efd !important; /* Standard primary color for valence */
    font-weight: 600 !important;
}
.riskMatrixModalCommentItem small {
    display: block !important;
    font-size: 0.8em !important;
    color: #6c757d !important; /* Bootstrap secondary text color */
    margin-top: 4px !important;
}

/* New Comment Area (Light Theme) */
.riskMatrixCommentModal .modal-body textarea.form-control {
    background-color: #ffffff !important; /* White input background */
    color: #212529 !important; /* Dark input text */
    border: 1px solid #ced4da !important; /* Standard input border */
}
.riskMatrixCommentModal .modal-body textarea.form-control::placeholder {
    color: #6c757d !important; /* Standard placeholder text color */
}
.riskMatrixCommentModal .modal-body textarea.form-control:focus {
    background-color: #ffffff !important;
    color: #212529 !important;
    border-color: #86b7fe !important; /* Standard Bootstrap focus border color */
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important; /* Standard focus glow */
}

/* Valence Radio Buttons (Light Theme) */
.riskMatrixModalValence {
  background-color: transparent !important; /* Ensure no conflicting background */
  color: #212529 !important; /* Dark text */
  border: 1px solid #dee2e6 !important; /* Standard light border */
  padding: 15px !important; /* More padding */
  margin-top: 1.5rem !important; /* More space */
  margin-bottom: 1.5rem !important;
  border-radius: 0.25rem !important;
}
.riskMatrixModalValence legend {
    font-size: 1em !important; /* Larger legend */
    font-weight: 500 !important;
    color: #212529 !important; /* Dark legend text */
    margin-bottom: 0.75rem !important; /* Space below legend */
    width: auto !important;
    padding: 0 5px !important;
}
.riskMatrixModalValence .form-check {
    display: inline-block !important;
    margin-right: 20px !important; /* More space between radio buttons */
}
.riskMatrixModalValence .form-check-label {
    font-weight: normal !important;
    color: #212529 !important; /* Dark label text */
    padding-left: 0.25rem !important;
}
.riskMatrixModalValence .form-check-input {
    background-color: #fff !important; /* White background for radio */
    border: 1px solid #b8b8b8 !important; /* Slightly darker border for unchecked radio */
}
.riskMatrixModalValence .form-check-input:checked {
    background-color: #0d6efd !important; /* Bootstrap primary color when checked */
    border-color: #0d6efd !important;
}
.riskMatrixModalValence .form-check-input:focus {
    border-color: #86b7fe !important;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important; /* Standard focus glow */
}

/* Intensity Slider (Light Theme) */
.riskMatrixModalIntensity {
    margin-top: 1.5rem !important; /* More space */
}
.riskMatrixModalIntensity label {
    display: block !important;
    margin-bottom: 0.5rem !important;
    color: #212529 !important; /* Dark label text */
    font-weight: 500 !important;
}
/* Style range input for light theme */
.riskMatrixCommentModal input[type="range"].form-range {
    background-color: #e9ecef !important; /* Light grey track background */
    border-radius: 1rem !important;
    height: 0.5rem !important; /* Standard track height */
    cursor: pointer !important;
    width: 100% !important; /* Ensure it takes full width */
    padding: 0 !important; /* Reset padding */
    vertical-align: middle !important; /* Align properly */
}
/* Style slider thumb for light theme */
.riskMatrixCommentModal input[type="range"].form-range::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    appearance: none !important;
    width: 1.25rem !important; /* Standard thumb width */
    height: 1.25rem !important; /* Standard thumb height */
    background-color: #0d6efd !important; /* Primary color thumb */
    border-radius: 50% !important;
    cursor: pointer !important;
    border: 0 !important; /* Remove default border */
    margin-top: -0.375rem !important; /* Center thumb vertically on track */
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}
.riskMatrixCommentModal input[type="range"].form-range::-moz-range-thumb {
    width: 1.25rem !important;
    height: 1.25rem !important;
    background-color: #0d6efd !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    border: 0 !important;
}
/* Active/Focus styling for thumb */
.riskMatrixCommentModal input[type="range"].form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}
.riskMatrixCommentModal input[type="range"].form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}


/* Modal Footer (Light Theme) */
.riskMatrixModalFooter {
  background-color: #f8f9fa !important; /* Light grey footer */
  border-top: 1px solid #dee2e6 !important; /* Standard light border */
  padding: 0.75rem 1rem !important; /* Adjust padding */
  display: flex !important; /* Use flexbox for alignment */
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: flex-end !important; /* Align buttons to the right */
}
/* Standard button styles for light theme - !important added */
.riskMatrixModalFooter .btn {
    margin-left: 0.25rem !important; /* Add space between buttons */
}
.riskMatrixModalFooter .btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
}
.riskMatrixModalFooter .btn-secondary {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}
.riskMatrixModalFooter .btn:disabled {
    color: #fff !important;
    background-color: #adb5bd !important; /* Lighter grey for disabled */
    border-color: #adb5bd !important;
    opacity: 0.65 !important; /* Standard disabled opacity */
}

/* ====================================================== */
/* --- End Modal Styles --- */
/* ====================================================== */