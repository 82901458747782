$primary-color: #22180c;
$secondary-color: #006eff;
$background-color: #bdb3b3;
$text-color: #ffffff;
$error-color: #ff6b6b;
$question-background: #16213e;
$answer-background: rgba(255, 255, 255, 0.1);

.databaseTool {
  background-color: $background-color;
  color: $text-color !important;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  font-family: 'Share Tech Mono', monospace;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
      color: $secondary-color !important;
      font-size: 2em;
    }

    .demoModeButton {
      background-color: $secondary-color;
      color: $text-color !important;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($secondary-color, 10%);
      }

      &.active {
        background-color: $primary-color;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .formSection {
    background-color: $question-background;
    padding: 15px;
    border-radius: 8px;

    .sectionTitle {
      color: $secondary-color !important;
      margin-bottom: 10px;
    }
  }

  .urlInputGroup {
    display: flex;
    gap: 10px;

    input[type="text"] {
      flex-grow: 1;
      padding: 10px;
      border-radius: 5px;
      border: none;
      background-color: $answer-background;
      color: $text-color !important;
    }

    .uploadButton {
      background-color: $secondary-color;
      color: $text-color !important;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($secondary-color, 10%);
      }

      input[type="file"] {
        display: none;
      }
    }
  }

  .ingressOptions, .questionTypeOptions {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .ingressButton {
    padding: 10px 20px;
    background-color: $primary-color;
    color: $text-color !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: inherit;
    font-size: 1em;
    transition: background-color 0.3s ease;

    &:hover:not(.disabled) {
      background-color: darken($primary-color, 10%);
    }

    &.active {
      background-color: $secondary-color;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .checkboxLabel {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.25em;
    color: $text-color !important;
    margin-left: 20px;
  }

  .generateButton {
    padding: 15px;
    background-color: $secondary-color;
    color: $text-color !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: inherit;
    font-size: 1.2em;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover:not(:disabled) {
      background-color: darken($secondary-color, 10%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .error {
    color: $error-color !important;
    margin-top: 15px;
    font-size: 1.2em;
  }

  .results {
    margin-top: 30px;

    h3 {
      color: $secondary-color !important;
      margin-bottom: 20px;
      font-size: 1.5em;
    }

    .addButton {
      margin-top: 20px;
      background-color: $secondary-color;
      color: $text-color !important;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-left: 10px;
      

      &:hover {
        background-color: darken($secondary-color, 10%);
      }
    }
  }

  .questionDisplay {
    background-color: $question-background;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    color: $text-color !important;
    position: relative;

    .questionText {
      font-size: 1.5em;
      margin-bottom: 10px;
      font-weight: 600;
      padding-right: 40px;  // Make room for the checkbox
    }

    .answerDisplay {
      background-color: $answer-background;
      border-radius: 4px;
      padding: 10px;
    }

    .freeformAnswer {
      width: 100%;
      min-height: 100px;
      background-color: $answer-background;
      border: none;
      border-radius: 4px;
      padding: 10px;
      color: $text-color !important;
      font-family: inherit;
      font-size: 1em;
      resize: vertical;
    }

    #binaryChoice {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 1rem;
    
      .radioOptionText {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.5rem;
        font-size: 1.5rem;
        color: $text-color !important;
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        transition: all 0.3s ease;
    
        &.selected {
          font-weight: 900;
          font-style: italic;
        }
    
        input[type="radio"] {
          display: none;
        }
    
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
    
        &.agree {
          background-color: #4caf50;
          color: $text-color !important;
    
          &:before {
            font-weight: 900;
            margin-right: 0.5rem;
          }
        }
    
        &.pass {
          background-color: #ffeb3b;
          color: $primary-color !important;
        }
    
        &.disagree {
          background-color: #f44336;
          color: $text-color !important;
    
          &:before {
            font-weight: 900;
            margin-right: 0.5rem;
          }
        }
    
        .optionIcon {
          margin-right: 0.5rem;
        }
      }
    }

    .ratingAnswer {
      display: flex;
      align-items: center;
      
      .ratingSlider {
        flex-grow: 1;
        margin-right: 10px;
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        background: $answer-background;
        outline: none;
        opacity: 0.7;
        transition: opacity .2s;

        &:hover {
          opacity: 1;
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: $secondary-color;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: $secondary-color;
          cursor: pointer;
        }
      }

      .ratingValue {
        font-size: 1.2em;
        font-weight: bold;
        color: $text-color !important;
      }
    }

    .multichoiceAnswer {
      list-style-type: none;
      padding-left: 0;

      .multichoiceOption {
        display: block;
        background-color: $answer-background;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 4px;
        color: $text-color !important;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: lighten($answer-background, 10%);
        }

        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
    }
  }

  .statementCheckbox {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .downloadSection {
    margin-top: 30px;

    h3 {
      color: $secondary-color !important;
      margin-bottom: 20px;
      font-size: 1.5em;
    }

    .downloadButtons {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      button {
        flex: 1;
        min-width: 200px;
        background-color: $secondary-color;
        color: $text-color !important;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($secondary-color, 10%);
        }
      }
    }
  }

  .helpIcon {
    color: $secondary-color !important;
    cursor: pointer;
    font-size: 1.2em;
    margin-left: 10px;
  }
}

// Media query for mobile devices
@media (max-width: 600px) {
  .databaseTool {
    padding: 15px;

    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .ingressOptions, .questionTypeOptions, .downloadButtons {
      flex-direction: column;

      button, .checkboxLabel {
        width: 100%;
      }
    }
  }
}

.pastedTextArea {
  width: 100%;
  min-height: 150px;
}

//

.resultsHeader {
  display: flex;
  flex-direction: row;
  font-size: 1.25em;
  font-weight: 600;
}