#sbtDropdown,
#customSbtAddressInput,
#sbtAddButton,
#customSbtAddButton,
#removeIncludedSbtButton,
#removeExcludedSbtButton,
#onlyVerifiedHumansCheckbox {
  color: black;
}

#sbtDropdown::placeholder,
#customSbtAddressInput::placeholder {
  color: black;
}

#sbtAddButton,
#customSbtAddButton,
#removeIncludedSbtButton,
#removeExcludedSbtButton {
  background-color: white;
  border: 1px solid #ccc;
}

#onlyVerifiedHumansCheckbox + label {
  color: black;
}

.optionLabel {
    display: flex;
    align-items: center;
    color: black !important;
  }
  
  .optionImage {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }


  .sbtThumbnail {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  //

  .addressTag {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 5px;
    background-color: #eee;
    border-radius: 4px;
  }
  
  .sbtName {
    margin-right: 10px;
  }
  
  .removeIcon {
    cursor: pointer;
    color: red;
    font-size: 1.5em; /* Increase the font size */
  }

  .greyedOutLabel {
    color: grey;
  }
  
  .greyedOutLabel input[type="checkbox"] {
    cursor: not-allowed;
  }
  
  .greyedOutLabel span {
    cursor: default;
  }
  
  
  // SBTFilter.jsx

  .sbtFilter {
    // background: #2a99eac7;
    // padding: 10px;
    // border-radius: 5px;
    // font-weight: 500;
  }

  .filterOptions {
    background: #2a99eac7;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
  }

  #humanOnlyCheckbox {
    color: black;
    font-weight: 600;
    font-size: 1.15em;
  }

  #verifiedHumansLabel {
    color: black;
    font-weight: 600;
    font-size: 1.15em;
  }

  .linkIcon {
    margin-left: 5px;
  }

#filterButton {
  background: #171941;
  color: white;
  font-size: 1.25em;
  display: flex;
  font-weight: 600;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#filterButtonIcon {
  margin-left: 10px;
}