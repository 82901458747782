$primary-color: #00ff77;
$secondary-color: #41a6e9;
$background-color: transparent; /* Standard background */
$text-color: #eeeeee; /* Standard text */
$highlight-color: #eaff00;
$green-color: #ffc400;
$modal-background-color: transparent; /* Modal background override */
$modal-text-color: #000000; /* Modal text override */

/* Base styles for the standard full-page list layout */
.standardBase {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Share Tech Mono', monospace;
  color: $text-color;
  background-color: $background-color;

  .sbtItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid $secondary-color;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    max-width: 800px;
    text-align: left;
    background-color: rgba($secondary-color, 0.6);
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba($secondary-color, 0.8);
      transform: translateY(-2px);
    }

    .sbtImage img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
      border: 2px solid $primary-color;
    }

    .sbtInfo {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      flex-grow: 1;

      .sbtName {
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between; // keeps lock icon on right
        color: $primary-color !important;
        font-weight: bold;
        font-size: 1.4em;
        margin-bottom: 5px;
      }

      .sbtDescription {
        color: $text-color !important;
        font-weight: 400;
        font-size: 1em;
        margin-bottom: 10px;
      }

      .sbtNetMinted {
        color: $green-color;
        font-weight: 600;
        font-size: 1.1em;
      }
    }

    .lockIcon {
      margin-left: 10px;
      font-size: 1.2em;
      color: white;
      opacity: 0.7;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
  }

  .refreshButton,
  .clearCacheButton,
  .expandButton {
    padding: 10px 20px;
    font-size: 1.15em;
    font-weight: 600;
    color: $text-color;
    background-color: $primary-color;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      background-color: lighten($secondary-color, 20%);
      cursor: not-allowed;
    }
  }

  .sectionTitle {
    width: 100%;
    max-width: 800px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 1.8em;
    font-weight: bold;
    color: $primary-color;
    text-align: left;
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
  }

  #featuredSBTsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    // max-width: 800px;
  }

  .filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;

    .filterLabel {
      font-size: 1em;
      color: $text-color;
      font-weight: 600;
      display: flex;
      align-items: center;

      input[type="checkbox"] {
        margin-right: 10px;
        width: 18px;
        height: 18px;
        accent-color: $primary-color;
      }
    }
  }

  .loadingSection {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 10px;

    .sbtItem {
      padding: 10px;
      max-width: 100%;

      .sbtImage img {
        width: 100px;
        height: 100px;
      }

      .sbtInfo {
        margin-left: 15px;
      }
    }

    .header {
      flex-direction: column;

      .refreshButton,
      .clearCacheButton,
      .expandButton {
        margin-bottom: 10px;
        width: 100%;
      }
    }

    .sectionTitle {
      font-size: 1.5em;
      text-align: center;
    }

    #featuredSBTsContainer {
      justify-content: center;
    }
  }
}

/* Base styles for the miniaturized list layout (used e.g. in a sidebar or modal) */
.miniaturizedBase {
  display: flex;
  flex-direction: column;
  font-family: 'Share Tech Mono', monospace;

  .sbtGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .sbtItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    background-color: #0a6afb;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 150px;
    text-align: center;

    &:hover {
      background-color: #e0e0e0;
      transform: translateY(-2px);
    }

    .sbtImage {
      width: 100%;
      img {
        max-width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }

    .sbtInfo {
      .sbtName {
        font-weight: bold;
        margin-top: 10px;
        color: #fff;
        text-align: center;
      }
      .sbtDescription {
        display: none;
      }
      .sbtNetMinted {
        font-size: 0.9em;
        color: #eee;
        margin-top: 5px;
      }
    }
    .lockIcon {
      margin-left: 5px;
      font-size: 0.8em;
      color: white;
      opacity: 0.7;
    }
  }

  .sectionTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.8em;
    font-weight: bold;
    text-align: center;
  }

  .filterContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .filterLabel {
      font-size: 0.95em;
      color: #000; /* or adapt if your modal background is dark */
      font-weight: 600;
      display: flex;
      align-items: center;

      input[type='checkbox'] {
        margin-right: 8px;
      }
    }
  }

  #loadingIcon {
    font-size: 2em;
    margin: 20px auto;
    display: block;
    color: #000; /* or adapt for your theme */
  }

  @media (max-width: 600px) {
    .sbtGrid {
      justify-content: center;
    }
    .sbtItem {
      width: clamp(120px, 45%, 150px);
      padding: 10px;
    }
    .sectionTitle {
      font-size: 1.5em;
    }
  }
}

/* --- VIEW MODE SPECIFIC OVERRIDES --- */
.standardViewContainer {
  /* For full-page /sbts view.  No changes needed here. */
}

.modalViewContainer {
  /* This modifies how the .miniaturizedBase looks in the modal. */
  background-color: $modal-background-color !important;
  color: $modal-text-color !important;
  padding: 20px;
  border-radius: 4px;

  .sbtGrid {
    /* Instead of single column, use a flex or grid layout so it’s more compact */
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    gap: 12px;
    max-height: 450px;
    overflow-y: auto;
  }

  .sbtItem {
    background-color: rgba(0,0,0,0.35) !important;
    width: 140px !important;
    margin: 10px !important;
    padding: 10px !important;

    &:hover {
      background-color: rgba(0,0,0,0.5) !important;
    }

    .sbtInfo {
      .sbtName {
        color: #ffffff !important;
        font-size: 1em;
      }
    }
    .lockIcon {
      color: #ffffff;
    }
  }

  .sectionTitle {
    color: $modal-text-color !important;
    border-bottom: 2px solid rgba($modal-text-color, 0.3);
  }

  .filterContainer {
    .filterLabel {
      color: $modal-text-color !important;
    }
  }
}

/* Optional spinner style */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: $text-color;
}
