/***************************************************
 * FILE: AudioInput.module.scss
 * LOCATION: my-app/client/src/components/SurveyTool/AudioInput.module.scss
 ***************************************************/

/* 
  The ENTIRE AudioInput container, row-reverse in desktop by default,
  but column-reverse in mobile so topControls appear at bottom on small screens.
*/
.audioInputContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

/* The row that holds AI rewrite + encryption, in a row on desktop, stacked on mobile */
.topControls {
  display: flex;
  flex-direction: row;
  // align-items: center;
  // margin-bottom: 8px;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
    margin-left: 10px;
  }
}

/* The text area + mic row, which is row-reverse on desktop, column on mobile */
.inputRow {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    // flex-direction: row-reverse;
    // align-items: center;
  }
}

/* The main text area */
.audioTextarea {
  flex: 1;
  font-size: 1.25em;
  color: grey;
  padding: 10px;
  border-radius: 4px;
  resize: vertical;
  min-height: 80px;
  margin-bottom: 0;
}

/* Microphone button, same as before */
.microphoneButton {
  background: transparent;
  border: none;
  font-size: 30px;
  color: grey;
  padding: 10px;
  cursor: pointer;
  border-radius: 10%;
  margin-left: 5px;
  opacity: 0.5;
  transition: color 0.2s ease, opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
    color: #aaa;
  }
}

.recording {
  color: red;
  opacity: 1;
}

/* AI rewrite button */
.aiRewriteButton {
  background: yellow;
  border: 1px solid #999;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 0px;
  color: #333;
  font-weight: 600;
  display: inline-flex
;
  align-items: center;
  justify-self: center;
  align-self: center;
  grid-gap: 6px;
  // gap: 6px;

  &:hover {
    background: #ffee66;
  }
}

/* Back arrow button to revert text */
.backArrowButton {
  background: yellow;
  border: 1px solid #999;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 4px;
  color: #333;
  font-weight: 600;

  &:hover {
    background: #ffee66;
  }
}

/* 
  Encryption button styling EXACTLY as old code “with no differences”.
  We rename them from #encryptOptionButton, #encryptIcon, #encryptCheckbox,
  #inputGroupTextBox to class names below:
*/

/* old #encryptOptionButton */
.encryptOptionButton {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  background-color: #00048080;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  cursor: crosshair;
}

/* old #encryptIcon */
.encryptIcon {
  font-size: 2em !important;
  margin-right: 15px;
  color: white;
  opacity: 0.25;
}

/* old #inputGroupTextBox */
.inputGroupText {
  border: 1px solid transparent;
  border-radius: 0px;
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}

/* old #encryptCheckbox */
.encryptCheckbox {
  margin-top: 5px;
  margin-bottom: 5px;
  transform: scale(1.5);
  margin-right: 10px;
}
