/* Styles moved from SurveyTool.module.scss for CreateSurvey.jsx */

.createSurveyContainer {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 20px;
    color: black !important;
  }
  
  .surveyTitleInput, #newSurveyTitleInput {
    font-size: 2em;
    font-weight: 600;
    background-color: transparent;
    color: black !important;
    border-radius: 0px;
    background: #f9f9f9;
    box-shadow: 5px 5px 10px #d6d6d6, -5px -5px 10px #ffffff;
    margin-bottom: 20px;
    min-height: 50px;
  }
  
  .questionContainer {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .questionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .questionTypeText, #questionTypeText {
    color: black;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.75;
    margin-right: 5px;
  }
  
  .removeQuestionButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    
    &:hover {
      background-color: #c82333;
    }
  }
  
  .questionPromptInput, #newQuestionInput {
    font-size: 1.25em;
    margin-bottom: 10px;
    color: black !important;
  }
  
  .optionsContainer {
    margin-top: 10px;
  }
  
  .optionItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: black !important;
  }
  
  .optionInput {
    flex-grow: 1;
    margin-right: 10px;
    color: black !important;
  }
  
  .removeOptionButton {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 5px 8px;
    border-radius: 3px;
    
    &:hover {
      background-color: #5a6268;
    }
  }
  
  .addOptionButton {
    margin-top: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    
    &:hover {
      background-color: #218838;
    }
  }
  
  .addQuestionContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .questionTypeSelect, #newQuestionSelect {
    flex-grow: 1;
    margin-right: 10px;
    font-size: 1.5em;
    font-weight: 600;
    padding: 5px;
  }
  
  .addQuestionButton, #addQuestionButton {
    color: white;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
  }
  
  .surveyIdPreview, #createSurveyIDPreview {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white !important; /* Updated to white as per example */
    font-size: 1em;
    font-weight: 600;
    margin-top: 10px;
    font-family: monospace;
    background-color: #1f2251; /* Updated to match theme */
    padding: 10px;
    border-radius: 5px;
  }
  
  .surveySubmittedContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .copySurveyLinkButton, .viewSurveyLink {
    flex: 1;
    margin: 0 5px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-weight: 600; /* Added from later styles */
    transition: background-color 0.3s ease; /* Added from later styles */
  }
  
  .copySurveyLinkButton {
    background-color: #28a745;
    border: none; /* Added from later styles */
    margin-right: 10px; /* Added from later styles */
    
    &:hover {
      background-color: #218838;
    }
  }
  
  .viewSurveyLink {
    background-color: #17a2b8;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      background-color: #138496;
      color: white;
      text-decoration: none; /* Added from later styles */
    }
  }
  
  .createSurveyButton, #submitNewSurveyButton { /* Renamed from .createSurveyButton in base */
    width: 100%;
    margin-top: 20px;
    background-color: black;
    color: white;
    border: 1px black solid;
    padding: 10px;
    font-size: 1.75em;
    font-weight: 600;
    text-transform: uppercase;
    
    &:hover {
      background-color: #333;
    }
    
    &:disabled {
      background-color: #6c757d;
      cursor: not-allowed;
    }
  }
  
  /* Progress Bar */
  .progressBarContainer {
    margin-top: 20px; /* Adjusted from 10px */
    background-color: #e9ecef;
    border-radius: 8px; /* Adjusted from 5px */
    overflow: hidden;
  }
  
  .progressBar {
    height: 10px;
    background-color: #28a745; /* Adjusted from #007bff */
    transition: width 0.5s ease;
  }
  
  /* JSON Display */
  .toggleJsonButton {
    margin-top: 20px;
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 15px; /* Adjusted padding */
    border-radius: 5px;
    font-weight: 600; /* Added */
    transition: background-color 0.3s ease; /* Added */
    
    &:hover {
      background-color: #5a6268;
    }
  }
  
  .jsonDisplay {
    margin-top: 20px;
    background-color: #037df8; /* Adjusted from #f8f9fa/#2931da */
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ced4da; /* Added border */
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 0.9em; /* Added font-size */
  }
  
  #expanded { /* Assuming this is related to the CreateSurvey expansion */
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #e0e0e0;
    margin-top: 10px;
    transition: max-height 0.5s ease-out;
  }
  
  /* Question Metadata and ID */
  .questionMetadata {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 0.8em;
    color: #666;
    margin-top: 5px;
  }
  
  .questionId { /* Used within questionMetadata */
    font-size: 0.65rem;
    opacity: 0.65;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px; /* Added */
  }
  
  .associatedSurveyContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  
  .associatedSurveyLabel {
    display: flex;
    align-items: center;
    margin-right: 10px;
    white-space: nowrap;
    color: #666 !important;
  }
  
  .addCurrentLink {
    margin-left: 5px;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer; /* Added */
    
    &:hover {
      color: #0056b3;
    }
  }
  
  .associatedSurveyInput {
    flex-grow: 1;
    color: black !important;
    font-size: 1em;
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .toggleLabel {
    font-size: 1.15em;
    color: black !important;
    font-weight: 600;
    opacity: 0.65;
    margin-bottom: 0px !important;
  }
  
  /* Miniaturized mode toggle */
  .modeToggle {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 1.25em;
    color: black;
    text-transform: uppercase;
  }
  
  .toggleSwitch { /* Specific styles for the switch UI */
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    width: 60px;
    height: 30px;
    vertical-align: middle;
  
    .toggleKnob {
      background-color: #fff;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      position: absolute;
      top: 1px;
      transition: left 0.2s;
    }
  }
  
  /* Submission Confirmation */
  .surveySubmissionConfirmation {
    margin-top: 30px;
    padding: 20px;
    background-color: #d4edda;
    border-radius: 8px;
    border: 1px solid #c3e6cb;
  
    h3 {
      color: #155724;
      margin-bottom: 15px;
    }
  
    p {
      font-size: 1.1em;
      color: #1e7e34;
    }
  }
  
  .uploadedQuestionsList {
    h4 {
      color: #343a40;
      font-weight: bold;
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    li a {
      flex-grow: 1;
      color: #007bff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  .copyQuestionIdButton,
  .bookmarkQuestionButton {
    background: none;
    border: none;
    color: #6c757d;
    font-size: 1.25em;
    margin-left: 10px;
    cursor: pointer;
  
    &:hover {
      color: #343a40;
    }
  }
  
  .submissionStatusBox .responseUrlBox {
    text-align: center;
    background-color: #f8f9fa; // Light background
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  .responseUrlBox {
    font-size: 1.25em;
    color: black !important;
  }
  
  /* Miniaturized Create Survey */
  #miniCreateSurveyLabel {
    font-weight: 600;
    font-size: 1.5em;
    color: black !important;
  }
  
  .tagsInput {
    color: black !important;
  }
  
  .errorButton {
    background-color: #dc3545;
    border-color: #dc3545;
    color: white;
  }
  
  .errorMessage {
    margin-top: 10px;
    color: #dc3545;
    font-weight: bold;
  }
  
  #copySurveyIdButton { /* Used in confirmation */
    margin-left: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  
  /* Miniaturized container */
  .miniaturized {
    padding: 10px; /* Reduced padding */
    /* Add other overrides if needed */
  }