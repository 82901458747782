.aboutPageContainer {
  /* 90% width, bigger max-width for easier reading */
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 1rem;

  /* Calmer pastel vibes with mild transparency */
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  line-height: 1.7;
  color: #b9dcd2; /* soft bluish-green text color */
  background-color: rgba(160, 200, 220, 0.1); /* pastel bluish with some transparency */
}

/* Main Titles */
.mainTitle {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #9ef7da; /* calm aquamarine for heading */
}

/* Sections */
.section {
  margin-bottom: 1.5rem;
  background-color: rgba(0, 0, 0, 0.2); /* subdued dark overlay */
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #78cfc4; /* calmer teal border */
}

.sectionTitle {
  font-size: 1.8rem;
  margin: 0;
  color: #9ef7da;
}

/* Toggle Headers */
.toggleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.toggleIcon {
  font-size: 1.8rem;
  color: #9ef7da;
  margin-left: 1rem;
}

/* Collapsible Content */
.collapsibleContent {
  margin-top: 1rem;
}

/* Custom Lists */
.customList {
  margin-left: 1.5rem;
  padding-left: 1rem;
  list-style-type: disc;
}

/* Roadmap Image */
.roadmapImage {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
  border: 2px solid #78cfc4;
  border-radius: 4px;
}

/* Optional Table styles (if needed) */
.tableWrapper {
  overflow-x: auto;
  margin-top: 1rem;
}

.infoTable {
  width: 100%;
  border-collapse: collapse;
  min-width: 650px;
  background-color: rgba(0, 0, 0, 0.2);
}

.infoTable thead {
  background-color: #444;
}

.infoTable th,
.infoTable td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #555;
}

.infoTable th {
  color: #9ef7da;
  font-weight: 500;
  white-space: nowrap;
  text-transform: uppercase;
}

.infoTable td {
  color: #b9dcd2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section {
    padding: 1rem;
  }

  .sectionTitle {
    font-size: 1.4rem;
  }

  .toggleIcon {
    font-size: 1.4rem;
  }

  .infoTable th,
  .infoTable td {
    font-size: 0.9rem;
  }
}

.demoVideo {
  display: flex;
  justify-self: center;
  min-width: 500px;
  min-height: 300px;
  margin: 10px;
}
